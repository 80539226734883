import create from "zustand";

export const useResturantCategory = create((set) => ({
  isCreateModal: false,
  id: null,
  name: null,
  description: '',
  queryKey: "",
  priority:null,
  
  setQueryKey: (queryKey) => set({ queryKey }),


  setPriority: (priority) => set({ priority }),
  setId: (id) => set({ id }),
  setName: (name) => set({ name }),
  setDescription: (description) => set({ description }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
