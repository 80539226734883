import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
  Upload,
  InputNumber,
  Select,
  DatePicker,
  Space,
  Card,
} from "antd";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import { useAppBanner } from "./store";
import { PureMapDrow } from "./mapDrow";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createBanner, editeBanner } from "../../api";
import moment from "moment";
import { useEffect, useState } from "react";
import { CustomeCalender } from "../../comonents/global/customeCalender";
import authFunc from "../../helper/auth";
import patternImg from "../../../src/assets/images/Pattern.png";
const { Title } = Typography;
const { Dragger } = Upload;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    polygon,
    setPolygon,
    setPriorty,
    setQueryKey,
    setId,
    setName,
    setType,
    setImg,
    name,
    type,
    priority,
    id,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setAppBannerVendor,
    // description,
    // setDescription,
    workingSchedule,
    setSchedule,
    setMarkerPosition,
    pinned_vendors,
    setPinned_vendors,
    pinned_distance_limit,
    setPinned_distance_limit,
 
  } = useAppBanner();

  const selectedTypes = [
    "CAROUSEL",
    "WEEKLY_DISCOUNT",
    "NEW",
    "CAROUSEL_LOGO",
    "WEEKLY_DISCOUNT_LOGO",
    "NEW_LOGO",
  ];

  const colors = [
    { name: "White", color: "#FFFFFF" },
    { name: "Gray", color: "#848484" },
    { name: "Blue 10", color: "#5CB5FF" },
    { name: "Blue 20", color: "#0043FF" },
    { name: "Beige", color: "#FEE5C0" },
    { name: "Orange", color: "#FC5437" },
    { name: "Green 10", color: "#01BD5D" },
    { name: "Green 20", color: "#007B19" },
    { name: "Midnight Blue", color: "#0D1623" },
  ];

  const patterns = [
    { id: 1, label: "pattern1", image: patternImg },
    { id: 2, label: "pattern2", image: patternImg },
   
  ];

  const [selectedColor, setSelectedColor] = useState('#FFFFFF');
  const [pattern,setPattern]=useState(null)
  const [form] = Form.useForm();

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };

  const resetState = () => {
    setPolygon(null);
    setPriorty(null);
    // setQueryKey(null);
    setId(null);
    setName(null);
    setType(null);
    setImg(null);
    setStartDate(null);
    setEndDate(null);
    setAppBannerVendor([]);
    // setDescription(null);
    setSchedule(null);
    setMarkerPosition(null);
    setPinned_vendors(0);
    setPinned_distance_limit(0);
    setPattern(null)
    setSelectedColor(null)
    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(createBanner, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("appBanner");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editeBanner,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("appBanner");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (polygon) {
      if (id) {
        let dataForm = {
          id,
          name: values.name,
          priority: values.priority,
          type: values.type,
          polygon,
          startDate: values.startDate,
          endDate: values.endDate,
          users: "[]",
          // description: values.description,
          pattern: values.pattern,
          bg_color: values.bg_color,
          pinned_distance_limit: values.pinned_distance_limit,
          pinned_vendors: values.pinned_vendors,
          workingSchedule: workingSchedule
            ? JSON.stringify(workingSchedule)
            : "[]",
        };
        editMutate(dataForm);
      } else {
        let dataForm = {
          imgFile: values.img ? values.img.file : null,
          data: JSON.stringify({
            appBanner_vendor: [],
            name: values.name,
            priority: values.priority,
            type: values.type,
            polygon,
            startDate: values.startDate,
            endDate: values.endDate,
            pattern: values.pattern,
            bg_color: values.bg_color,
            // description: values.description,
            workingSchedule: workingSchedule
              ? JSON.stringify(workingSchedule)
              : "[]",
          }),
        };
        mutate(dataForm);
      }
    } else {
      message.error("Polygon Not found ");
    }
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        type,
        priority,
        // description,
        pattern,
        selectedColor,
        pinned_vendors,
        pinned_distance_limit,
        startDate: startDate ? moment(startDate) : null,
        endDate: endDate ? moment(endDate) : null,
        schedule: workingSchedule,
      });
    } else {
      form.resetFields();
      resetState();
    }
  }, [id]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} appBanner</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={880}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <PureMapDrow />
          </Col>
          <Col span={12}>
            <Row gutter={[20, 20]}>
              <Col span={16}>
                <Form.Item
                  label="Banner Name"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Banner title" />
                </Form.Item>
              </Col>
              <Col span={8}>
              
                      <Form.Item
                        label="Priority"
                        name="priority"
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                          style={{ width: "100%" }}
                        />
                     
                </Form.Item>
              </Col>

              {/* <Col span={24}>
                <Form.Item
                  label="Banner Description"
                  name="description"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="Banner Description" />
                </Form.Item>
              </Col> */}
              {!id && (
                <Col span={24}>
                  <Form.Item
                    label="Banner Type"
                    name="type"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      <Option value={"CAROUSEL_LOGO"}>Carousel (Logo)</Option>
                      <Option value={"WEEKLY_DISCOUNT_LOGO"}>Weekly Discount (Logo)</Option>
                      <Option value={"NEW_LOGO"}>New (Logo)</Option>
                    
                      <Option value={"CAROUSEL"}>Carousel (List)</Option>
                      <Option value={"WEEKLY_DISCOUNT"}>Weekly Discount (List)</Option>
                      <Option value={"NEW"}>New (List)</Option>
                      <Option value={"LIST"}>List</Option>
                      <Option value={"FOOD_PARTY"}>Food Party</Option>
                      <Option value={"SLIDER"}>Slider</Option>
                      <Option value={"BANNER"}>Banner</Option>
                      <Option value={"CATEGORY"}>Category</Option>
                      {/* <Option value={"BEST_SELLER"}>BEST_SELLER</Option> */}
                    </Select>
                  </Form.Item>
                </Col>
              )}

            

              <Col span={24}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime
                  />
                </Form.Item>
              </Col>
              {id && authFunc("PIN_SETTINGS_CREATE") ? (
                <>
                  <Col span={12}>
                    <Form.Item
                      label="Pinned Vendors"
                      name="pinned_vendors"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        defaultValue={0}
                        min={0}
                        style={{ width: "100%" }}
                        onChange={(val) => {
                          setPinned_vendors(val);
                          if (val === 0) {
                            setPinned_distance_limit(0);
                            form.setFieldsValue({
                              pinned_distance_limit: 0,
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Pinned Distance Limit"
                      name="pinned_distance_limit"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        defaultValue={0}
                        min={0}
                        disabled={pinned_vendors === 0}
                        style={{ width: "100%" }}
                        addonAfter={"KM"}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null}

              {!id ? (
                <Col span={24}>
                  <Form.Item shouldUpdate>
                  

                      <Form.Item
                        label="Image"
                        name="img"
                        rules={
                          selectedTypes.includes(form.getFieldValue("type"))
                            ? []
                            : [{ required: true, message: "Please upload an image!" }]
                        }
                      >
                        <Dragger {...props}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-hint">
                            Click or drag Image to this area
                          </p>
                        </Dragger>
                      </Form.Item>
                  </Form.Item>
                </Col>
              ) : null}

<Col span={24}>
        <Form.Item
          label="BackgroundColor"
          name="bg_color"
          rules={[{ required: true, message: "Please select a color!" }]}
        >
          <Select
            placeholder="Choose the color"
            value={selectedColor} 
            onChange={(value) => {
              setSelectedColor(value); 
              form.setFieldsValue({ bg_color: value });
              console.log("Selected Color:", value);
            }}
            dropdownRender={(menu) => (
              <div style={{ padding: "10px" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "10px",
                  }}
                >
                  {colors.map((item) => (
                    <div
                      key={item.color}
                      onClick={() => {
                        setSelectedColor(item.color); 
                        form.setFieldsValue({ bg_color: item.color }); 
                      }}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "5px",
                        borderRadius: "5px",
                        border:
                          selectedColor === item.color
                            ? "2px solid #1890ff"
                            : "1px solid #ddd",
                        backgroundColor:
                          selectedColor === item.color ? "#f0faff" : "#fff",
                      }}
                    >
                      <div
                        style={{
                          height: "25px",
                          width: "25px",
                          backgroundColor: item.color,
                          borderRadius: "50%",
                        }}
                      />
                      <span style={{ fontWeight: "bold", color: "#333" }}>
                        {item.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          >
            {colors.map((item) => (
              <Option key={item.color} value={item.color}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

<Col span={24}>
        <Form.Item label="Pattern" name="pattern" rules={[{ required: true }]}>
          <Select
            placeholder="Choose the Pattern"
            value={pattern}
            onChange={(value) => {
              setPattern(value); 
              form.setFieldsValue({ pattern: value }); 
            }}
            dropdownRender={(menu) => (
              <div style={{ padding: "10px" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "10px",
                  }}
                >
                  {patterns.map((item) => (
                    <div
                      key={item.id}
                      onClick={() => {
                        setPattern(item.label); 
                        form.setFieldsValue({ pattern: item.label });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Card
                        bordered={pattern === item.label}
                        style={{
                          width: 150,
                          textAlign: "center",
                          borderColor:
                            pattern === item.label ? "#1890ff" : "#d9d9d9",
                        }}
                      >
                        <Title
                          level={5}
                          style={{
                            color: pattern === item.label ? "#1890ff" : "#000",
                          }}
                        >
                          {item.label}
                        </Title>
                        <img
                          src={item.image}
                          alt={item.label}
                          style={{ width: "100%", height: "auto" }}
                        />
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            )}
          >
            {patterns.map((item) => (
              <Option key={item.id} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
            </Row>
          </Col>
          
          <Col span={24}>
            <Form.Item
              label="Schedule"
              name="schedule"
              rules={[{ required: true, message: "schedule is required" }]}
             
            >
              <CustomeCalender
                value={id ? workingSchedule : null}
                onChange={(val) => setSchedule(val)}
                isAll={() => null}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
