import { useEffect } from "react";
import {
  Table,
  Tag,
  Alert,
  Typography,
  Pagination,
  Popover,
  Button,
  Space,
} from "antd";
import { useQuery } from "react-query";
import {
  EditOutlined,
  BellOutlined,
  UserOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { getOrders, pageSize } from "../../api";
import { useOrder } from "./store";
import authFunc from "../../helper/auth";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import getTimeDifferenceInMinutes from "../../helper/getTimeDifferenceInMinutes";
const { Text } = Typography;

export const PureTable = () => {
  const {
    queryKey,
    setPage,
    page,
    queryID,
    vendorID,
    cityID,
    clientID,
    status,
    voucherCode,
    from,
    to,
    preOrder,
  } = useOrder();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (_, row) => (
        <Space size={0} direction="vertical">
          <Space size={4}>
            <Text style={{ color: "#666" }}>ID: </Text>
            <Text style={{ fontWeight: "bold" }}>{row.id}</Text>
          </Space>
          <Space size={4}>
            <Text style={{ color: "#666" }}>TrackNo: </Text>
            <Text style={{ fontWeight: "bold" }}>{row.trackNo}</Text>
          </Space>
        </Space>
      ),
    },
    {
      title: "Customer",
      dataIndex: "client",
      key: "client",
      // width: 100,
      render: (client, row) => (
        <Space size={0} direction="vertical">
          <Space size={4}>
            <UserOutlined style={{ fontSize: 12 }} />
            <Text style={{ fontWeight: "bold" }}>{client || "..."}</Text>
          </Space>
          <Popover
            content={
              <Typography.Title level={5}>
                {row?.clientAddress}
              </Typography.Title>
            }
          >
            <Space size={4}>
              <EnvironmentOutlined style={{ fontSize: 12 }} />
              <Text>
                {row?.clientAddress?.length > 20
                  ? `${row?.clientAddress?.substr(0, 20)}...`
                  : row?.clientAddress}
              </Text>
            </Space>
          </Popover>
        </Space>
      ),
    },
    {
      title: "SubTotal",
      dataIndex: "subTotal",
      key: "subTotal",
      render: (subTotal) => Number(subTotal).toLocaleString(),
    },
    {
      title: "Delivery",
      dataIndex: "deliveryFee",
      key: "deliveryFee",
      render: (deliveryFee, rows) =>
        rows?.deliveryDiscountTotalValue > 0
          ? Number(deliveryFee) - Number(rows?.deliveryDiscountTotalValue)
          : Number(deliveryFee),
    },
    {
      title: "Delivery Discount",
      dataIndex: "deliveryDiscountTotalValue",
      key: "deliveryDiscountTotalValue",
      render: (deliveryDiscountTotalValue) =>
        deliveryDiscountTotalValue > 0 ? Number(deliveryDiscountTotalValue) : 0,
    },

    {
      title: "Discount",
      dataIndex: "id",
      key: "id",
      render: (id, row) => {
        let voucher = row?.discountTotalValue + row?.voucherTotalValue;
        return (
          <i style={{ color: voucher ? "red" : "gray" }}>
            {voucher > 0 ? "-" + Number(voucher).toLocaleString() : voucher}
          </i>
        );
      },
    },

    {
      title: "TotalValue",
      dataIndex: "totalBeforeRound",
      key: "totalBeforeRound",
      render: (totalBeforeRound) => (
        <b>{Number(totalBeforeRound).toLocaleString()}</b>
      ),
    },
    {
      title: "Rounded Total Value",
      dataIndex: "totalValue",
      key: "totalValue",
      render: (totalValue) => <b>{Number(totalValue).toLocaleString()}</b>,
    },
    {
      title: "Resturant",
      dataIndex: "vendor",
      key: "vendor",
      render: (vendor) => (
        <Popover
          content={<Typography.Title level={5}>{vendor}</Typography.Title>}
        >
          {vendor?.length > 20 ? `${vendor?.substr(0, 20)}...` : vendor}
        </Popover>
      ),
    },
    {
      title: "Creation Date",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      render: (CreatedAt) => (
        <Space size={0} direction="vertical">
          <Text style={{ fontSize: 12 }} type="secondary">
            {dayjs(CreatedAt).format("YYYY/MM/DD")}
          </Text>
          <Text>{dayjs(CreatedAt).format("hh:mm A")}</Text>
        </Space>
      ),
    },

    {
      title: "Payment",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (paymentType) => (
        <Tag color={paymentType === "ONLINE" ? "green" : "magenta"}>
          {paymentType ? paymentType : "..."}
        </Tag>
      ),
    },

    {
      title: "Voucher",
      dataIndex: "voucherCode",
      key: "voucherCode",
      render: (voucherCode) => <Text>{voucherCode ? voucherCode : "..."}</Text>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let statusHistory = status ? JSON.parse(status) : [];
        let currentStatus = status ? JSON.parse(status).pop() : "";
        return (
          <Popover
            title="Status History"
            content={
              <div>
                {statusHistory.map(
                  (el, index) =>
                    el.status && (
                      <p key={index}>
                        <b style={{ color: el.color }}>{el.status}</b>/ changed
                        by
                        <b>
                          {el?.userType === "BIKER"
                            ? el?.biker_name
                            : el?.userName}
                        </b>
                        (<small>{el.userType}</small>) /
                        <Text type="secondary">
                          {dayjs(el.created_at).format("hh:mm a")}
                        </Text>
                      </p>
                    )
                )}
              </div>
            }
          >
            <Space>
              <a style={{ color: currentStatus.color }}>
                {currentStatus.arTitle}
              </a>
              {currentStatus.status === "SUSPEND" ? (
                <BellOutlined className="rings-alert" />
              ) : null}
              {currentStatus.status === "ONLINE_PAYMENT_PENDING" &&
              getTimeDifferenceInMinutes(currentStatus.created_at) > 5 ? (
                <BellOutlined
                  className="rings-alert"
                  style={{
                    color: "red",
                  }}
                />
              ) : null}
            </Space>
          </Popover>
        );
      },
    },

    {
      title: "PreOrder?",
      dataIndex: "preOrder",
      key: "preOrder",
      render: (val, row) =>
        val ? (
          <>
            <Tag color={"geekblue"}>Pre-Order</Tag>
            <br />
            <Tag color={"geekblue"}>
              {dayjs(row.preOrderDeliveryTime).isSame(dayjs(), "day")
                ? `today/${dayjs(row.preOrderDeliveryTime).format("h:mmA")}`
                : dayjs(row.preOrderDeliveryTime).isSame(
                    dayjs().add(1, "day"),
                    "day"
                  )
                ? `tomorrow/${dayjs(row.preOrderDeliveryTime).format("h:mmA")}`
                : dayjs(row.preOrderDeliveryTime).format("MM/DD/YYYY h:mmA")}
            </Tag>
          </>
        ) : (
          "..."
        ),
    },
    {
      title: "Hybrid Payment",
      dataIndex: "hybridPaymentEnabled",
      key: "hybridPaymentEnabled",
      render: (hybridPaymentEnabled) => (
        <Tag color="magenta">{hybridPaymentEnabled ? "ON" : "OFF"}</Tag>
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (id) => (
        <Space>
          {authFunc("ORDER_UPDATE") && (
            <Button
              onClick={() => window.open(`/order/${id}`)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
        </Space>
      ),
    },
  ];

  const { isLoading, error, data } = useQuery(
    [
      "orders",
      queryKey,
      page,
      queryID,
      vendorID,
      cityID,
      clientID,
      status,
      voucherCode,
      from,
      to,
      preOrder,
    ],
    getOrders,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      refetchInterval: 30000,
      enabled: !!from,
    }
  );

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}

        //scroll={{ x: 1500 }}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
