import { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Pagination,
  message,
  Popconfirm,
  Tooltip,
  Switch,
  Alert,
} from "antd";
import { CopyOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { getBanners, removeData, pageSize, editeBanneStatus } from "../../api";
import { useAppBanner } from "./store";
import { useApp } from "../../store";
import authFunc from "../../helper/auth";
import { PureGrid } from "./grid";
import { ImageTable } from "../../comonents/global";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const copy = require("clipboard-copy");

const { Text, Link } = Typography;

export const PureTable = () => {
  const [page, setPage] = useState(1);
  const {
    queryKey,
    isGrid,
    selectedType,
    setId,
    setImg,
    setType,
    setStartDate,
    setEndDate,
    setName,
    setPolygon,
    setIsCreateModal,
    setPriorty,
    setAppBannerVendor,
    setIsAddVendor,
    setUsers,
    setIsAddUsers,
    setIsAddCat,
    setDescription,
    setCategories,
    setSchedule,
    setIsAddBanners,
    setBanners,
    setIsAddProduct,
    setProductLimit,
    setOrderLimit,
    setPinned_vendors,
    setPinned_distance_limit,
  } = useAppBanner();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setImg(row.img);
    setType(row.type);
    setStartDate(row.startDate);
    setEndDate(row.endDate);
    setName(row.name);
    setPolygon(row.polygon);
    setPriorty(row.priority);
    setDescription(row.description);
    setPinned_vendors(row.pinned_vendors);
    setPinned_distance_limit(row.pinned_distance_limit);
    setAppBannerVendor(
      row.appBanner_vendor ? row.appBanner_vendor.map((el) => el.vendor) : []
    );
    setIsCreateModal(true);
    setSchedule(
      JSON.parse(row.workingSchedule)?.length > 0
        ? JSON.parse(row.workingSchedule)
        : null
    );
  };
  const handleAddVendors = (row) => {
    setId(row.id);
    setType(row.type);
    setAppBannerVendor(
      row.appBanner_vendor ? row.appBanner_vendor.map((el) => el.vendor) : []
    );
    setIsAddVendor(true);
  };
  const handleAddProducts = (row) => {
    setId(row.id);
    setOrderLimit(row.orderLimit);
    setProductLimit(row.productLimit);
    setAppBannerVendor(
      row.appBanner_vendor ? row.appBanner_vendor.map((el) => el.vendor) : []
    );
    setIsAddProduct(true);
  };

  const handleAddBanners = (row) => {
    setId(row.id);
    setType(row.type);
    setBanners(row.banner || []);
    setIsAddBanners(true);
  };

  const handleAddUsers = (row) => {
    setId(row.id);
    setUsers(row.users ? JSON.parse(row.users) : []);
    setIsAddUsers(true);
  };
  const handleAddCats = (row) => {
    setId(row.id);
    setCategories(row.category || []);
    setIsAddCat(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Banner",
      key: "id",
      render: (row) => (
        <ImageTable
          logo={false}
          url={row.img}
          id={row.id}
          pathName={"appbanners/img"}
          imgKey={"imgFile"}
          updateStore={"appBanner"}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <>
          <Text style={{ fontWeight: "bold" }}>{text} </Text>
          <br />
          {row.description ? (
            <span style={{ color: "grey" }}>{row.description}</span>
          ) : null}
        </>
      ),
    },

    {
      title: "Image Link",
      dataIndex: "img",
      key: "img",
      render: (text) => (
        <Space className="table-link">
          <Link
            className="app-media-link"
            style={{ textDecoration: "underline" }}
          >
            {text}
          </Link>
          <Tooltip placement="top" title="copy image link">
            <Button
              onClick={() => copyLink(text)}
              size="small"
              type="text"
              icon={<CopyOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => <Tag color="purple">{type}</Tag>,
    },
    {
      title: "Display Priority",
      dataIndex: "priority",
      key: "priority",
      render: (rank) => (
        <Tag color="magenta">{Number(rank).toLocaleString()}</Tag>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          disabled={ !authFunc("APPBANNER_UPDATE")}
          loading={selectedID === row.id && editLoading}
          checked={!!row?.active}
          onChange={(state) => handleChangeActive(row)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Space>
          {authFunc("APPBANNER_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("APPBANNER_UPDATE") && (
            <Tooltip placement="top" title="Add Banners to this banner">
              <Button
                onClick={() => handleAddBanners(row)}
                size="small"
                disabled={
                  String(row.type) === "NEW" ||
                  String(row.type) === "WEEKLY_DISCOUNT" ||
                  String(row.type) === "BEST_SELLER" ||
                  String(row.type) === "CAROUSEL" ||
                  String(row.type) === "BANNER" ||
                  String(row.type) === "LIST" ||
                  String(row.type) === "FOOD_PARTY"
                    ? true
                    : false
                }
              >
                Banners
              </Button>
            </Tooltip>
          )}
          {authFunc("APPBANNER_UPDATE") && (
            <Tooltip placement="top" title="Add vendors to this banner">
              <Button
                onClick={() => handleAddVendors(row)}
                size="small"
                disabled={
                  String(row.type) === "NEW" ||
                  String(row.type) === "WEEKLY_DISCOUNT" ||
                  String(row.type) === "SLIDER" ||
                  String(row.type) === "BEST_SELLER"
                    ? true
                    : false
                }
              >
                Vendors
              </Button>
            </Tooltip>
          )}
          {authFunc("APPBANNER_UPDATE") && (
            <Tooltip
              placement="top"
              title={
                String(row.type) === "FOOD_PARTY"
                  ? row.vendors > 0
                    ? "Add products to this banner"
                    : "Add a vendor first"
                  : "Only for food party"
              }
            >
              <Button
                onClick={() => handleAddProducts(row)}
                size="small"
                disabled={
                  row.vendors > 0 && String(row.type) === "FOOD_PARTY"
                    ? false
                    : true
                }
              >
                Products
              </Button>
            </Tooltip>
          )}
          {authFunc("APPBANNER_UPDATE") && (
            <Tooltip placement="top" title="Add users to this banner">
              <Button
                onClick={() => handleAddUsers(row)}
                size="small"
                disabled={
                  String(row.type) === "SLIDER" ||
                  String(row.type) === "FOOD_PARTY"
                    ? true
                    : false
                }
              >
                Users
              </Button>
            </Tooltip>
          )}
          {authFunc("APPBANNER_UPDATE") && (
            <Tooltip placement="top" title="Add categories to this banner">
              <Button
                onClick={() => handleAddCats(row)}
                size="small"
                disabled={
                  String(row.type) === "NEW" ||
                  String(row.type) === "WEEKLY_DISCOUNT" ||
                  String(row.type) === "SLIDER" ||
                  String(row.type) === "BEST_SELLER" ||
                  String(row.type) === "FOOD_PARTY"
                    ? true
                    : false
                }
              >
                Categories
              </Button>
            </Tooltip>
          )}
          {authFunc("APPBANNER_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editeBanneStatus,
    {
      onSuccess: (data) => {
        if (data.success) {
          setSelectedID(null);
          message.success(data.msg);
          queryClient.invalidateQueries("appBanner");
        } else {
          setSelectedID(null);
          message.error(data.err);
        }
      },
      onError: (e) => {
        setSelectedID(null);
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    setSelectedID(row.id);
    editMutate({
      id: row.id,
      active: !row.active,
      type: row.type,
    });
  };

  const copyLink = (val) => {
    let successPromise = copy(val);
    if (successPromise) {
      message.success("Copy to clipboard");
    } else {
      message.error("Faild Copy !");
    }
  };

  const { mutate, isLoading: addLoading } = useMutation(
    (val) => removeData("appbanners", val),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("appBanner");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["appBanner", selectedType, queryKey, page], getBanners, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return isGrid ? (
    <>
      <PureGrid
        loading={loading || addLoading}
        data={data ? data.data.records : []}
      />
      <br />
      <Pagination
        onChange={(val) => setPage(val)}
        current={page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  ) : (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
