import create from "zustand";

export const useAppBanner = create((set) => ({
  workingSchedule: null,
  isCreateModal: false,
  queryKey: "",
  img: null,
  name: null,
  polygon: null,
  priority: null,
  type: null,
  startDate: null,
  endDate: null,
  id: null,
  appBanner_vendor: [],
  markerPosition: null,
  banner: [],
  isAddBanners: false,
  isGrid: false,
  selectedType: "",
  isAddVendor: false,
  isAddProduct: false,
  isAddUsers: false,
  isAddCat: false,
  users: [],
  categories: [],
  isPreview: false,
  lat: 33.3152,
  lon: 44.3661,
  description: null,
  productLimit: null,
  orderLimit: null,
  pinned_vendors: 0,
  pinned_distance_limit: 0,



  setLat: (lat) => set({ lat }),
  setLon: (lon) => set({ lon }),
  setIsPrivew: (isPreview) => set({ isPreview }),
  setPinned_vendors: (pinned_vendors) => set({ pinned_vendors }),
  setPinned_distance_limit: (pinned_distance_limit) =>
    set({ pinned_distance_limit }),
  setProductLimit: (productLimit) => set({ productLimit }),
  setOrderLimit: (orderLimit) => set({ orderLimit }),
  setCategories: (categories) => set({ categories }),
  setAppBannerVendor: (appBanner_vendor) => set({ appBanner_vendor }),
  setMarkerPosition: (markerPosition) => set({ markerPosition }),
  setIsAddVendor: (isAddVendor) => set({ isAddVendor }),
  setIsAddProduct: (isAddProduct) => set({ isAddProduct }),
  setIsAddUsers: (isAddUsers) => set({ isAddUsers }),
  setIsAddCat: (isAddCat) => set({ isAddCat }),
  setSelectedType: (selectedType) => set({ selectedType }),
  setIsGrid: (isGrid) => set({ isGrid }),
  setId: (id) => set({ id }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setStartDate: (startDate) => set({ startDate }),
  setEndDate: (endDate) => set({ endDate }),
  setImg: (img) => set({ img }),
  setName: (name) => set({ name }),
  setPriorty: (priority) => set({ priority }),
  setType: (type) => set({ type }),
  setPolygon: (polygon) => set({ polygon }),
  setDescription: (description) => set({ description }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setUsers: (users) => set({ users }),
  setBanners: (banners) => set({ banners }),
  setSchedule: (workingSchedule) => set({ workingSchedule }),
  setIsAddBanners: (isAddBanners) => set({ isAddBanners }),
}));
